import React from 'react'
import classNames from 'classnames'

export const OuterContainer = ({ className, children }) => (
  <div
    className={classNames(
      'container flex flex-col items-center justify-center gap-6 2xl:gap-12 2xl:pt-12',
      className
    )}
  >
    {children}
  </div>
)

export const InnerContainer = ({ className, children }) => (
  <div
    className={classNames(
      'flex w-80 flex-col items-center gap-6 text-center lg:w-[75vw] 2xl:w-[38.75rem] 2xl:gap-12',
      className
    )}
  >
    {children}
  </div>
)
